import { Transport } from '@frontend/shared/transport';
import { IResponseData, IStartBankIDReturnType } from 'APP/interfaces/authentication.interfaces';

export const AuthActions = {
    /**
     * Starts the BankID verification process for the applicant.
     * @param {string} ssn
     */
    startBankID(ssn: string): Promise<IStartBankIDReturnType> {
        return Transport.post('auth/bankid/auth', { data: { ssn } });
    },

    /**
     * Gets the BankID status of the applicant.
     * @param {string} reference
     */
    getBankIDStatus(reference: string): Promise<IResponseData> {
        return Transport.get(`auth/bankid/auth/${reference}`);
    },

    /**
     * Updates the BankID challenge reference on the applicant.
     * @param {string} applicantId
     * @param {object} data
     * @returns {Promise<any>} The promise that resolves with the server response data.
     */
    updateApplicantsBankIdReference(applicantId: string, data: { [key: string]: string }): Promise<IResponseData> {
        return Transport.patch(`applicants/mortgage/${applicantId}/bankid`, { data });
    },

    validateJWT(): Promise<IResponseData> {
        return Transport.get('auth/validate');
    },

    refreshJWT(): Promise<IResponseData> {
        return Transport.get('auth/refresh');
    },

    /**
     * @param {string} urlToken
     */
    urlTokenLogin(urlToken: string): Promise<IResponseData> {
        return Transport.post('auth/login', { data: { url_token: urlToken } });
    },

    close(): Promise<IResponseData> {
        return Transport.delete('auth/session');
    },
};
